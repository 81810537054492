import {
  CheckPlusSafeMissControllerService,
  MissMemberInfoControllerService,
  NameCheckControllerService,
} from '@/__generated__/CommonApi';
import {
  MySupportInfoControllerService,
  MyMissHistoryModuleControllerService,
  RegularPayInfoVo,
  myMissModuleVo,
  certificationVo,
} from '@/__generated__/FrontApi';
import ArrowLeft from '@/assets/img/arrow_left_sky_7x11@2x.png';
import IconModuleHandle from '@/assets/img/icons/icon_support_module_handle_up@2x.png';
import HeaderLogo from '@/assets/img/logo_active@2x.png';
import HeaderLogoWht from '@/assets/img/logo_white@2x.png';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import LabelText from '@/components/Form/LabelText';
import Row from '@/components/Grid/Row';
import InputText from '@/components/Input/InputText';
import PayInfoChangeLayerPopup, {
  PayInfoChangeResult,
} from '@/components/Layer/PayInfoChangeLayerPopup';
import MyPayInformation from '@/components/Mypage/MyPayInformation';
import { NotiBox, NotiTitle, NotiParagraph } from '@/components/Text/TextNoti';
import NiceAuthenticationMissForm from '@/components/ThirdParty/NiceAuthenticationMissForm';
import { H2_3, H2_2 } from '@/components/Titles';
import GlobalStyle from '@/GlobalStyle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { mobileRegex } from '@/helpers/ValidationHelper';
import BaseLayout from '@/layouts/BaseLayout';
import { FormButton } from '@/page-blocks/auth/AuthCommon';
import MyPageSupportHistoryTab2 from '@/page-blocks/mypage/support-history/MyPageSupportHistoryTab2';
import { useCertificationStore } from '@/stores/CertificationStore';
import { usePopupStore } from '@/stores/PopupStore';
import { PageProps } from 'gatsby';
import { observable, runInAction } from 'mobx';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import SessionStorage, {
  SESSION_STORAGE_KEY__DONATE_CRYPTO,
} from '@/services/SessionStorage';

export interface User {
  userId: string;
  name: string;
  email: string;
  mobilePhoneNumber: string;
  socialId: string | null;
  socialType: 'G' | 'F' | 'N' | null;
  // 마스킹된 주민번호
  juminno: string;
  juminnoLength: number;
  // 대시 없는 주민번호. 개인정보 이슈로 전송 중단
  // fullJuminno: string;
  // 생일 (웹DB)
  birthDay: string | null; // YYMMDD
  fullBirthDay: string | null; // YYYYMMDD

  // 후원정보
  donorId: string;
  donorType: 'person' | string;
  donorTypeCode: 'IN' | string;
  donorYn: 'Y' | 'N';
  firstDonateDate: string | null; // 첫 후원일
  accumTotalAmount: number; // 누적 총 후원금액
  donorHistoryCount: number; // 실제 결제한 히스토리 카운트 (후원한적 있는지 여부 판단용)
  ptypeCount: number; // 정기후원 횟수
  gtypeCount: number; // 일시후원 횟수
  regularTotalAmount: number; // 현재 정기후원 총 금액

  // 광고관련
  emailAgreeYn: 'Y' | 'N';
  smsAgreeYn: 'Y' | 'N';
  mailEdm: 'Y' | 'N';
  mailSms: 'Y' | 'N';
  mailDm: 'Y' | 'N';

  // 주소
  zip: string;
  address1: string;
  address2: string;

  // 기타 설정
  isRewardYn: 'Y' | 'N';
  businessNumber: string;
}

const ModuleHeader = styled.h1``;
const ModuleText = styled.div`
  width: calc(100% - 594px);
  margin-top: 100px;
  .tit {
    font-size: 60px;
    font-weight: 700;
    color: #1cabe2;
  }
  .txt {
    font-size: 18px;
    margin-top: 50px;
    line-height: 1.78;
  }
  ${breakpoint(`mobile`)} {
    width: 100%;
    margin-top: 60px;
    .tit {
      font-size: 30px;
    }
    .txt {
      font-size: 14px;
      margin-top: 30px;
    }
  }
`;
const ModuleBg = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  padding: 70px 80px;
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  .header_logo {
    display: block;
    width: 109px;
    height: 58px;
    font-size: 0;
    background: url(${HeaderLogo}) no-repeat;
    background-size: contain;
  }

  &.bg_dark {
    .header_logo {
      background: url(${HeaderLogoWht}) no-repeat;
      background-size: contain;
    }
    ${ModuleText} {
      .tit {
        color: #fff;
      }
      .txt {
        color: #fff;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    padding: 35px 25px;
    overflow: hidden;

    .header_logo {
      width: 68px;
      height: 36.5px;
    }
  }
`;
const ModuleHandle = styled.div<{ isOpen: boolean }>`
  display: none;
  ${breakpoint(`tablet`)} {
    display: block;
    position: fixed;
    top: ${(props) => (props.isOpen ? `calc(100svh / 2)` : `100%`)};
    transform: ${(props) =>
      props.isOpen ? `translateY(0)` : `translateY(-100%)`};
    left: 0;
    width: 100%;
    height: auto;
    background-color: #1cabe2;
    padding: 5px;

    .mobile-handle {
      width: 72px;
      height: 24px;
      background-color: #1cabe2;
      background-image: url(${IconModuleHandle});
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 24px;
      border-radius: ${(props) =>
        props.isOpen ? `0 0 6px 6px` : `6px 6px 0 0`};
      position: absolute;
      top: -24px;
      left: 50%;
      transform: ${(props) =>
        props.isOpen ? `translateX(-50%) rotate(180deg)` : `translateX(-50%)`};
    }
    .mobile-toolbar-text {
      display: ${(props) => (props.isOpen ? `none` : `block`)};
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      line-height: 1.63;
      letter-spacing: -0.8px;
      color: #fff;
    }
  }
`;
const ModuleBox = styled.div<{ isOpen: boolean }>`
  width: 594px;
  height: 820px;
  padding: 0 30px;
  background: #fff;
  border-radius: 30px 0 30px 0;
  position: absolute;
  top: 5%;
  right: 80px;

  ${breakpoint(`tablet`)} {
    left: 0;
    right: 0;
    top: ${(props) => (props.isOpen ? `calc(100svh / 2)` : `100%`)};
    width: 100%;
    height: calc(100vh / 2);
    height: calc(100svh / 2);
    overflow-y: auto;
    border-radius: 0;
    padding: 0 20px 20px;
  }
`;
const ModuleHead = styled.div`
  font-weight: 700;
  color: #1cabe2;
  border-bottom: 1px solid #1cabe2;
`;
const ModuleTitle = styled.div`
  font-size: 24px;
  padding: 60px 0 30px;
  ${breakpoint(`mobile`)} {
    font-size: 18px;
    padding: 25px 0;
  }
`;
const ModuleContent = styled.div`
  padding-top: 40px;
  overflow: auto;
  width: 100%;
  // max-height: 300px;
  ${NotiBox} {
    margin-top: 24px;
    background: #e2f0f6;

    a {
      font-size: 24px;
      text-decoration: underline;
      letter-spacing: -1.2px;
    }
    ${NotiParagraph} {
      font-weight: 400;
      color: #1cabe2;
    }
  }
  ${FormGroup} {
    width: 100%;
    overflow-x: hidden;
  }
  ${breakpoint(`mobile`)} {
    padding-top: 35px;
    overflow: visible;
  }
`;
const PaymentEditTitle = styled.div`
  padding: 40px 0 20px;
  .btn_missSuppot {
    font-size: 20px;
    font-weight: 700;
    color: #1cabe2;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 7px;
      height: 11px;
      background: url(${ArrowLeft}) no-repeat;
      background-size: contain;
      margin-right: 10px;
    }
  }
  ${H2_3} {
    display: block;
    margin-left: auto;
  }
  ${H2_2} {
    font-size: 22px;
    color: #2d2926;
    margin-top: 10px;
  }
  ${breakpoint(`mobile`)} {
    .btn_missSuppot {
      font-size: 16px;
    }
    ${H2_3} {
      font-size: 18px;
    }
    ${H2_2} {
      font-size: 17px;
      margin-top: 30px;
    }
  }
`;
const PaymentEdit = styled.div`
  max-height: 560px;
  overflow: auto;
  ${breakpoint(`mobile`)} {
    max-height: auto;
    overflow: visible;
  }
`;
const MissSupport = styled.div``;
const MissSupportHeader = styled.div`
  font-size: 20px;
  strong {
    font-weight: 700;
    color: #1cabe2;
  }
  ${Row} {
    text-align: right;
    margin: 10px 0 15px;
    align-items: flex-end;
    .miss-tit {
      font-size: 18px;
      font-weight: 700;
    }
  }
  ${breakpoint(`mobile`)} {
    font-size: 16px;
    ${Row} {
      .miss-tit {
        font-size: 15px;
      }
      ${Button} {
        max-width: 128px;
        font-size: 14px;
      }
    }
  }
`;
const NoSupport = styled.div`
  ${NotiBox} {
    ${NotiTitle} {
      font-size: 18px;
      font-weight: 700;
      color: #1cabe2;
    }
    ${NotiParagraph} {
      font-weight: 400;
      color: #1cabe2;
      .btn_mypage {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
  .btn_wrap {
    ${NotiParagraph} {
      font-size: 18px;
      font-weight: 400;
    }
    .btn_apply {
      font-weight: 700;
      color: #1cabe2;
      text-decoration: underline;

      &::after {
        content: '>';
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
`;
const NoSupportHeader = styled.div`
  font-size: 20px;
  strong {
    font-weight: 700;
    color: #1cabe2;
  }
`;

interface FindIdFormData {
  mobilePhoneNumber: string;
}

const DonateModule: FC<PageProps> = ({ location, ...props }) => {
  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const certificationStore = useCertificationStore();
  const popupStore = usePopupStore();
  const {
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FindIdFormData>({
    mode: `onChange`,
  });
  const [isEditingPayInfo, setIsEditingPayInfo] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [result, setResult] = useState<PayInfoChangeResult>();
  // 휴대폰 인증코드 확인 여부
  const [isMobileAuthCodeValid, setIsMobileAuthCodeValid] = useState<boolean>(
    false,
  );
  // 모바일 모듈 오픈 여부
  const [isMobModuleOpen, setIsMobModuleOpen] = useState<boolean>(true);

  // 인증 회원 후원 여부
  const [isDonorYn, setIsDonorYn] = useState<boolean>(false);
  const [isModule, setIsModule] = useState(false); // 모듈로드
  const [payInfo, setPayInfo] = useState<RegularPayInfoVo>();
  const [keyGenState, setKeyGenState] = useState(false);

  // 결제정보 로드
  const loadPaymentInfo = useCallback(async () => {
    const {
      data,
    } = await MySupportInfoControllerService.getMyPayInfoUsingGet();
    setPayInfo(data);
  }, []);
  const [moduleInfo, setModuleInfo] = useState<myMissModuleVo>() as any; // 모듈 디자인 정보
  const [memberInfo, setMemberInfo] = useState<certificationVo>() as any; // 본인 인증 한 회원 정보
  const [urlKeyword, setUrlKeyword] = useState(``); // 모듈 키워드
  const cpCode = new URLSearchParams(location.search); // 파라미터 가져오기
  const moduleCode = cpCode.get('cpCode');

  // 버튼 사용 여부 존재 여부
  // const [isDisplayOption, setIsDisplayOption] = useState<boolean>(false);

  // 모듈 디자인 정보 로드
  const loadModuleInfo = useCallback(async () => {
    const cpCode = moduleCode as any;
    SessionStorage.setItem('cpCode', cpCode);
    const {
      data,
    } = await MyMissHistoryModuleControllerService.getMyMissModuleInfoGet({
      cpCode: cpCode,
    });
    setModuleInfo(data);
    SessionStorage.setItem(
      `or.kr.unicef.miss.moduleCode`,
      data.moduleCode as any,
    );
    SessionStorage.setItem(
      `or.kr.unicef.miss.displayOption`,
      data.displayOption as any,
    );
    SessionStorage.setItem(
      `or.kr.unicef.miss.solicitCode`,
      data.solicitCode as any,
    );
    //SessionStorage.setItem(`or.kr.unicef.miss.moduleCode`,moduleInfo.moduleCode as any);
    //SessionStorage.setItem(`or.kr.unicef.miss.displayOption`,moduleInfo.displayOption as any);
    //SessionStorage.setItem(`or.kr.unicef.miss.solicitCode`,moduleInfo.solicitCode as any);
    const missSession = SessionStorage.getItem(`or.kr.unicef.miss.oauth.token`);

    if (missSession) {
      const idSession = SessionStorage.getItem(
        `or.kr.unicef.miss.oauth.token_info`,
      ) as any;
      setIsMobileAuthCodeValid(true);
      //setIsMobileAuthCodeValid(true);
      setIsDonorYn(true);
      loadPaymentInfo();
    } else {
    }
  }, []);

  const sessionChk = () => {
    const now = new Date(); // 현재 시간
    const loginTime = SessionStorage.getItem(
      `or.kr.unicef.miss.createTime`,
    ) as any;
    if (null != loginTime) {
      const afterLogin = (now.getTime() - parseInt(loginTime)) / (60 * 1000);
      if (afterLogin > 20) {
        //숫자=분
        alert('세션이 만료되었습니다. 다시 인증 해주세요.');
        SessionStorage.clear();
        //location.href ="/donate/overdue/?cpCode="+sessionStorage.getItem(`cpCode`);
        window.location.reload();
      } else {
        SessionStorage.removeItem(`or.kr.unicef.miss.createTime`);
        const date = new Date();
        SessionStorage.setItem(
          `or.kr.unicef.miss.createTime`,
          date.getTime() as any,
        );
      }
    }
  };

  useEffect(() => {
    sessionChk();
    //loadPaymentInfo();
    loadModuleInfo();
    setIsModule(true);

    const idSession = SessionStorage.getItem(
      `or.kr.unicef.miss.oauth.token_info`,
    ) as any;
    if (idSession) {
      loadUserInfo(idSession);
    }
  }, [loadPaymentInfo, loadModuleInfo]);

  const loadUserInfo = useCallback(async (donorId: string) => {
    const missSession = SessionStorage.getItem(`or.kr.unicef.miss.oauth.token`);
    const encKey = JSON.parse(
      SessionStorage.getItem(SESSION_STORAGE_KEY__DONATE_CRYPTO) as any,
    );
    const {
      data: originalData,
      resultCode,
    } = await CheckPlusSafeMissControllerService.missCertUsingPost({
      //id: memberInfo?.loginId as unknown as string,
      id: donorId,
      encCert: encKey?.cert,
      publicKey: encKey?.key,
      expiry: encKey?.expiry,
    });
    // 타입에러 보정
    const data = originalData as any;
    if (resultCode === `success` && null != data) {
      SessionStorage.setItem(`or.kr.unicef.miss.oauth.token`, data.missDonorId);
      SessionStorage.setItem(
        `or.kr.unicef.miss.oauth.token_info`,
        data.missUserId,
      );
      SessionStorage.setItem(
        `or.kr.unicef.miss.oauth.code`,
        data.donorTypeCode,
      );
      SessionStorage.setItem(`or.kr.unicef.path.token`, '2');

      const {
        data: _data,
        resultCode,
      } = await MissMemberInfoControllerService.MissMemberLoginInfoUsingGet({
        id: data.missDonorId,
        encCert: encKey?.cert,
        publicKey: encKey?.key,
        expiry: encKey?.expiry,
      });
      const memberData = _data as User;

      if (resultCode === `success`) {
        runInAction(() => {
          console.info('회원 정보 가져오기');
          certificationStore.user = memberData;
          SessionStorage.setItem('cerName', memberData.name);
          SessionStorage.setItem(
            'cerMount',
            memberData.accumTotalAmount as any,
          );
          let firstDonateDate = null;
          if (
            data.firstDonateDate === undefined ||
            data.firstDonateDate === null
          ) {
            firstDonateDate = '';
          } else {
            firstDonateDate = data.firstDonateDate as any;
          }

          SessionStorage.setItem('cerFirst', firstDonateDate);
          certificationStore.user.birthDay = SessionStorage.getItem('birthDay');
          // 로딩 완료
        });
      }
      setIsMobileAuthCodeValid(true);
      loadPaymentInfo();
      setIsDonorYn(true);
    } else {
      setIsMobileAuthCodeValid(true);
      setIsDonorYn(false);
    }
  }, []);

  const today = new Date();

  let backImg;
  if (typeof window !== 'undefined') {
    if (/iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent)) {
      // mobile
      backImg = moduleInfo?.imgAttGrpNoOpt2;
    } else {
      // desktop
      backImg = moduleInfo?.imgAttGrpNoOpt1;
    }
  }

  return (
    <BaseLayout>
      <GlobalStyle />
      <ModuleBg
        {...props}
        className="bg_dark" /* 어두운 배경일 경우 class 추가 */
        id="bg_dark"
        css={`
          background-image: url(${backImg});
        `}
      >
        <ModuleHeader>
          <a href="/" className="header_logo" target="_blank">
            Unicef
          </a>
        </ModuleHeader>
        <ModuleText>
          <p
            className="tit"
            dangerouslySetInnerHTML={{ __html: moduleInfo?.mainMessage }}
          ></p>
          <div
            className="txt"
            dangerouslySetInnerHTML={{ __html: moduleInfo?.subMessage }}
          ></div>
        </ModuleText>

        <ModuleHandle
          className="mobile-toolbar"
          isOpen={isMobModuleOpen}
          onClick={() => setIsMobModuleOpen(!isMobModuleOpen)}
        >
          <div className="mobile-handle" />
          <div className="mobile-toolbar-text"></div>
        </ModuleHandle>
        <ModuleBox isOpen={isMobModuleOpen}>
          <ModuleHead>
            {isEditingPayInfo ? (
              <PaymentEditTitle>
                <Row>
                  <button
                    type="button"
                    className="btn_missSuppot"
                    onClick={() => {
                      sessionChk();
                      if (payInfo?.isModifyOkYn === `Y`) {
                        setIsEditingPayInfo(false);
                      }
                    }}
                  >
                    놓친 후원 보기
                  </button>
                  <H2_3>결제 정보 변경</H2_3>
                </Row>
                <H2_2>결제 정보</H2_2>
              </PaymentEditTitle>
            ) : (
              <ModuleTitle
                dangerouslySetInnerHTML={{ __html: moduleInfo?.moduleName }}
              ></ModuleTitle>
            )}
          </ModuleHead>
          <ModuleContent
            onClick={sessionChk}
            onBlur={sessionChk}
            onFocus={sessionChk}
            onKeyPress={sessionChk}
            onKeyUp={sessionChk}
          >
            {!isMobileAuthCodeValid ? (
              /* 인증 전 : 본인인증 */
              <FormGroup>
                <LabelText require>본인인증</LabelText>
                <Row className="certify" alignItems="flex-start">
                  <div className="col-left">
                    {isMobileAuthCodeValid ? (
                      <FormButton color="grey" disabled>
                        인증완료
                      </FormButton>
                    ) : (
                      <NiceAuthenticationMissForm
                        onSuccess={(result) => {
                          setValue(`mobilePhoneNumber`, result.mobile);
                          clearErrors(`mobilePhoneNumber`);
                          if (result.resultCode === `success`) {
                            setKeyGenState(true);
                            const now = new Date().getTime();
                            NameCheckControllerService.generateSirenNameCryptoKey().then(
                              ({ data }) => {
                                //console.log('++ enc key gen : 암호화키 생성시작');
                                SessionStorage.setItem(
                                  SESSION_STORAGE_KEY__DONATE_CRYPTO,
                                  JSON.stringify({
                                    key: data.key,
                                    cert: data.cert,
                                    expiry: data.expiry,
                                    isSecureContext: isSecureContext,
                                  }),
                                );
                                //console.log('++ enc key gen : 암호화키 생성완료');
                                setMemberInfo(result);
                                loadUserInfo(result.donorId as any);
                                SessionStorage.setItem(
                                  'gender',
                                  result.gender as any,
                                );
                                SessionStorage.setItem(
                                  'birthDay',
                                  result.birthDay as any,
                                );
                                SessionStorage.setItem(
                                  'or.kr.unicef.miss.id',
                                  result.userId as any,
                                );
                                const date = new Date();
                                SessionStorage.setItem(
                                  `or.kr.unicef.miss.createTime`,
                                  date.getTime() as any,
                                );
                              },
                            );
                          } else {
                            //setMemberInfo(result);
                            setIsMobileAuthCodeValid(true);
                            setIsDonorYn(false);
                          }
                        }}
                        renderSubmitButton={(props) => (
                          <FormButton
                            outline
                            disabled={moduleInfo?.displayStateTypeCode !== `3`}
                            //disabled={moduleInfo?.displayStateTypeCode < 3}
                            //disabled={moduleInfo?.displayStateTypeCode === 4}
                            // color={authType === `P` ? `blue` : `grey`}
                            {...props}
                          >
                            본인인증
                          </FormButton>
                        )}
                      />
                    )}
                  </div>
                  <div className="col-right">
                    <InputText
                      name="mobilePhoneNumber"
                      ref={register({
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                        pattern: {
                          value: mobileRegex,
                          message: `올바른 휴대폰번호가 아닙니다`,
                        },
                        validate: {
                          isValidRange: (value) =>
                            (value.substr(0, 3) === `010` &&
                              value.length === 11) ||
                            (value.substr(0, 3) !== `010` &&
                              value.length === 10) ||
                            `휴대폰번호 자릿수를 확인해주세요`,
                        },
                      })}
                      placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                      readOnly
                    />
                    {errors.mobilePhoneNumber && (
                      <ErrorMessage>
                        {errors.mobilePhoneNumber.message}
                      </ErrorMessage>
                    )}
                  </div>
                </Row>
                <NotiBox>
                  <NotiParagraph>
                    먼저 본인인증을 해주세요. PASS앱 또는 문자메시지로 간편하게
                    가능합니다.
                  </NotiParagraph>
                </NotiBox>
              </FormGroup>
            ) : moduleInfo.displayOption.indexOf(`M`) >= 0 ? (
              /* 인증 후 */
              <>
                {/* 결제정보변경 */}
                {isEditingPayInfo ? (
                  <PaymentEdit>
                    <MyPayInformation
                      supporterType={certificationStore.user?.donorType}
                      memberTypeCode={certificationStore.user?.donorTypeCode}
                      payInfo={payInfo}
                      onSuccess={(result) => {
                        setResult(result);
                        setIsEditingPayInfo(false);
                        loadPaymentInfo();
                      }}
                    />
                  </PaymentEdit>
                ) : (
                  <>
                    {/* 놓친 후원 */}
                    {isDonorYn ? (
                      <MissSupport>
                        <MissSupportHeader>
                          <p>
                            <strong>
                              {certificationStore.user?.name
                                ? certificationStore.user?.name
                                : SessionStorage.getItem(`cerName`)}
                            </strong>
                            {` `}후원자님,
                            {certificationStore.user?.firstDonateDate
                              ? certificationStore.user?.firstDonateDate
                              : sessionStorage.getItem(`cerFirst`)}
                            {certificationStore.user?.firstDonateDate ? (
                              <>부터 </>
                            ) : SessionStorage.getItem(`cerFirst`) ? (
                              <>부터 </>
                            ) : payInfo?.gifdate ? (
                              <>
                                <>첫 후원 예정일은 </>
                                {payInfo?.gifdate}
                                <>
                                  {' '}
                                  입니다<br></br>
                                </>
                              </>
                            ) : (
                              <></>
                            )}
                            <strong>
                              {certificationStore.user?.accumTotalAmount
                                ? Number(
                                    certificationStore.user?.accumTotalAmount,
                                  ).toLocaleString()
                                : Number(
                                    SessionStorage.getItem(`cerMount`),
                                  ).toLocaleString()}
                            </strong>
                            원 후원하셨습니다.
                          </p>
                          <Row>
                            <div className="miss-tit">
                              {today.getFullYear()}년도 내가 놓친 후원
                            </div>
                            {moduleInfo.displayOption.indexOf(`C`) >= 0 ? (
                              <Button
                                size="sm"
                                color="gray"
                                outline
                                onClick={() => {
                                  if (payInfo?.isModifyOkYn === `Y`) {
                                    setIsEditingPayInfo(true);
                                    return;
                                  }
                                  popupStore.show(
                                    `청구일에는 결제수단 변경이 불가합니다.\n(문의: 02-737-1004)`,
                                  );
                                }}
                              >
                                결제 정보 변경
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </MissSupportHeader>

                        <MyPageSupportHistoryTab2
                          params={params}
                          className="module-missHistory"
                        />
                        {moduleInfo.infoMessage ? (
                          moduleInfo.infoMessage
                        ) : (
                          <></>
                        )}
                      </MissSupport>
                    ) : (
                      <NoSupport>
                        <NoSupportHeader>
                          <p>
                            <strong>{memberInfo?.name}</strong>
                            {` `}후원자님, <br />
                            정기후원 신청 내역이 없습니다.
                          </p>
                        </NoSupportHeader>
                        <NotiBox>
                          <NotiTitle>정기후원자라면</NotiTitle>
                          <NotiParagraph>
                            <a
                              href="/mypage"
                              target="_blank"
                              className="btn_mypage"
                            >
                              마이페이지
                            </a>
                            에서 이름과 생년월일, 휴대폰 번호가 맞는지 확인
                            바랍니다. <br />
                            이름, 생년월일, 휴대폰 번호가 일치하는데 정기후원
                            정보를 불러오지 못한다면 02-737-1004로 문의해주세요.
                          </NotiParagraph>
                        </NotiBox>
                        <div className="btn_wrap">
                          <NotiParagraph>
                            {moduleInfo?.supportUrlMessage
                              ? moduleInfo?.supportUrlMessage
                              : '정기후원을 신청한 적 없다면'}
                          </NotiParagraph>
                          <a
                            href={moduleInfo?.supportUrl}
                            target="_blank"
                            className="btn_apply"
                          >
                            {moduleInfo?.supportUrlTitle
                              ? moduleInfo?.supportUrlTitle
                              : '정기후원 신청 하러 가기'}
                          </a>
                        </div>
                      </NoSupport>
                    )}
                  </>
                )}
              </>
            ) : (
              /* 인증 후 */
              <>
                {isDonorYn ? (
                  <PaymentEdit>
                    <MyPayInformation
                      supporterType={certificationStore.user?.donorType}
                      memberTypeCode={certificationStore.user?.donorTypeCode}
                      payInfo={payInfo}
                      onSuccess={(result) => {
                        setResult(result);
                        setIsEditingPayInfo(true);
                        loadPaymentInfo();
                      }}
                    />
                  </PaymentEdit>
                ) : (
                  <>
                    <NoSupport>
                      <NoSupportHeader>
                        <p>
                          <strong>{memberInfo?.name}</strong>
                          {` `}후원자님, <br />
                          정기후원 신청 내역이 없습니다.
                        </p>
                      </NoSupportHeader>
                      <NotiBox>
                        <NotiTitle>정기후원자라면</NotiTitle>
                        <NotiParagraph>
                          <a
                            href="/mypage"
                            target="_blank"
                            className="btn_mypage"
                          >
                            마이페이지
                          </a>
                          에서 이름과 생년월일, 휴대폰 번호가 맞는지 확인
                          바랍니다. <br />
                          이름, 생년월일, 휴대폰 번호가 일치하는데 정기후원
                          정보를 불러오지 못한다면 02-737-1004로 문의해주세요.
                        </NotiParagraph>
                      </NotiBox>
                      <div className="btn_wrap">
                        <NotiParagraph>
                          {moduleInfo?.supportUrlMessage
                            ? moduleInfo?.supportUrlMessage
                            : '정기후원을 신청한 적 없다면'}
                        </NotiParagraph>
                        <a
                          href={moduleInfo?.supportUrl}
                          target="_blank"
                          className="btn_apply"
                        >
                          {moduleInfo?.supportUrlTitle
                            ? moduleInfo?.supportUrlTitle
                            : '정기후원 신청 하러 가기'}
                        </a>
                      </div>
                    </NoSupport>
                  </>
                )}
              </>
            )}
            {!!result && (
              <PayInfoChangeLayerPopup
                isOpen
                onRequestClose={() => setResult(undefined)}
                result={result}
              />
            )}
          </ModuleContent>
        </ModuleBox>
      </ModuleBg>
    </BaseLayout>
  );
};
export default DonateModule;
